import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EventSource } from 'utils/enums';

type catalogState = {};

const initialState: catalogState = {};

type PayloadActionWithEventSource<T> = PayloadAction<{ value: T; source: EventSource }>;

// Kept around for userTracking
const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadActionWithEventSource<string>) => {},
    setSearchCat: (state, action: PayloadActionWithEventSource<string>) => {},
    seeUpdates: (state) => {},
  },
});

// eslint-disable-next-line import/no-unused-modules
export const {
  setSearch,
  setSearchCat,
  seeUpdates,
} = catalogSlice.actions;
export default catalogSlice.reducer;
